function RightIcon () {
  return (
    <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_192_14034)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.8982 6.61601C17.1762 6.61601 15.1761 1.45672 15.0898 1.22977C14.9931 0.969595 14.7973 0.758337 14.5453 0.642095C14.2932 0.525853 14.0054 0.513848 13.7447 0.60922C13.4829 0.708646 13.2712 0.908095 13.1558 1.16328C13.0404 1.41847 13.0307 1.70884 13.129 1.97111C13.8485 3.74596 14.9618 5.33428 16.3848 6.61601H1.38385C1.12669 6.64691 0.889824 6.77094 0.717954 6.9647C0.546083 7.15846 0.451172 7.40825 0.451172 7.66726C0.451172 7.92626 0.546083 8.17642 0.717954 8.37018C0.889824 8.56394 1.12669 8.68797 1.38385 8.71887H16.3848C14.9618 10.0006 13.8485 11.5889 13.129 13.3638C13.0803 13.4929 13.0576 13.6303 13.062 13.7682C13.0665 13.9062 13.098 14.0418 13.1549 14.1675C13.2118 14.2933 13.293 14.4067 13.3936 14.5011C13.4943 14.5955 13.6126 14.6692 13.7418 14.7179C13.8709 14.7666 14.0083 14.7892 14.1462 14.7848C14.2842 14.7803 14.42 14.7486 14.5457 14.6917C14.6715 14.6348 14.7847 14.5538 14.8791 14.4531C14.9735 14.3524 15.0471 14.2343 15.0958 14.1051C15.1155 14.0446 17.1504 8.71887 21.9042 8.71887C22.1612 8.68724 22.3978 8.56259 22.5691 8.36833C22.7404 8.17408 22.8346 7.92368 22.8339 7.66467C22.8331 7.40566 22.7375 7.15575 22.5651 6.96249C22.3926 6.76922 22.1554 6.64617 21.8982 6.61601Z" fill="black"/>
      </g>
      <defs>
      <clipPath id="clip0_192_14034">
        <rect width="22.5991" height="14.237" fill="white" transform="translate(0.344238 0.548828)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
export default function Partners() {
  return (
    <div className="CPCM" id="partners">
      <h2>Partners</h2>
      <div className="CPCM-content">
        {/* host */}
        <div className="hasBg">
          <img src={'/partners/p00.svg'} alt="" className="host" />
          <RightIcon />
        </div>
        <div><img src={'/partners/p01.svg'} alt="" /></div>
        <div><img src={'/partners/p02.svg'} alt="" /></div>
        <div><img src={'/partners/p03.svg'} alt="" /></div>
        {/* Sponsor */}
        <div className="hasBg">
          <img src={'/partners/p10.svg'} alt="" />
          <RightIcon />
        </div>
        <div><img src={'/partners/p11.svg'} alt="" /></div>
        <div><img src={'/partners/p13.svg'} alt="" /></div>
        <div><img src={'/partners/p14.svg'} alt="" /></div>
        <div><img src={'/partners/p121.svg'} alt="" /></div>
        <div><img src={'/partners/p120.svg'} alt="" /></div>
        <div><img src={'/partners/p12.svg'} alt="" /></div>
        <div><img src={'/partners/p122.svg'} alt="" /></div>
        {/* <div><img src={'/partners/p19.svg'} alt="" /></div> */}
        <div><img src={'/partners/p17.svg'} alt="" /></div>
        <div><img src={'/partners/p16.svg'} alt="" /></div>
        <div><img src={'/partners/p15.svg'} alt="" /></div>
        <div><img src={'/partners/p18.svg'} alt="" /></div>
        
        {/* Partnerships */}
        <div className="hasBg">
          <img src={'/partners/p20.svg'} alt="" />
          <RightIcon />
        </div>
        
        
        <div><img src={'/partners/p21.svg'} alt="" /></div>
        <div><img src={'/partners/p22.svg'} alt="" /></div>
        <div><img src={'/partners/p23.svg'} alt="" style={{width: '50%'}} /></div>
        <div><img src={'/partners/p26.svg'} alt="" /></div>
        <div><img src={'/partners/p24.svg'} alt="" /></div>
        <div><img src={'/partners/p313.svg'} alt="" /></div>
        
        {/* Community Support */}
        <div className="hasBg">
          <img src={'/partners/p30.svg'} alt="" className="community" />
          <RightIcon />  
        </div>
        <div><img src={'/partners/p31.svg'} alt="" /></div>
        <div><img src={'/partners/p32.svg'} alt="" /></div>
        <div><img src={'/partners/p33.svg'} alt="" /></div>
        <div><img src={'/partners/p34.svg'} alt="" /></div>
        <div><img src={'/partners/p35.svg'} alt="" /></div>
        <div><img src={'/partners/p36.svg'} alt="" /></div>
        <div><img src={'/partners/p37.svg'} alt="" /></div>
        <div><img src={'/partners/p38.svg'} alt="" /></div>
        <div><img src={'/partners/p25.svg'} alt="" /></div>
        <div><img src={'/partners/p310.svg'} alt="" /></div>
        <div><img src={'/partners/p311.svg'} alt="" /></div>
        <div><img src={'/partners/p312.svg'} alt="" /></div>
        
        <div><img src={'/partners/p314.svg'} alt="" /></div>
        <div><img src={'/partners/p315.svg'} alt="" /></div>
        <div><img src={'/partners/p316.svg'} alt="" /></div>
        <div><img src={'/partners/p317.svg'} alt="" /></div>
        
        {/* Media Support */}
        <div className="hasBg">
          <img src={'/partners/p40.svg'} alt="" className="university" />
          <RightIcon />
        </div>
        
        <div><img src={'/partners/p41.svg'} alt="" /></div>
        
        {/* University Support */}
        <div className="hasBg">
          <img src={'/partners/p50.svg'} alt="" className="media" />
          <RightIcon />
        </div>
        <div><img src={'/partners/p51.svg'} alt="" /></div>
        <div><img src={'/partners/p52.svg'} alt="" /></div>
        <div><img src={'/partners/p53.svg'} alt="" /></div>
        <div><img src={'/partners/p54.svg'} alt="" /></div>
        <div><img src={'/partners/p55.svg'} alt="" /></div>
        <div><img src={'/partners/p56.svg'} alt="" /></div>
        <div><img src={'/partners/p57.svg'} alt="" /></div>
        {/* Media */}
        <div className="hasBg">
          <img src={'/partners/media.svg'} alt="" className="media" />
          <RightIcon />
        </div>
        <div><img src={'/partners/p58.svg'} alt="" /></div>
        <div><img src={'/partners/p59.svg'} alt="" /></div>
        <div><img src={'/partners/p510.svg'} alt="" /></div>
        <div><img src={'/partners/p511.svg'} alt="" /></div>
        <div><img src={'/partners/p512.svg'} alt="" /></div>
        <div><img src={'/partners/p513.svg'} alt="" /></div>
        <div><img src={'/partners/p514.svg'} alt="" /></div>
        <div><img src={'/partners/p515.svg'} alt="" /></div>
        <div><img src={'/partners/p516.svg'} alt="" /></div>
        <div><img src={'/partners/p517.svg'} alt="" /></div>
      </div>
    </div>
  )
}