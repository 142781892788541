import Speaker22 from './assets/speaker22.png'
import Speaker23 from './assets/speaker23.png'
import Speaker32 from './assets/speaker32.png'
import Speaker101 from './assets/speaker101.png'
import Speaker103 from './assets/speaker103.png'

const data = [
  
  {
    name: 'Fubiao Xia',
    info: 'Co-Founder @PADO Labs',
    img: '/speakers/1.png',
  },
  {
    name: 'Aleck',
    info: 'Sr. SA @AWS',
    img: '/speakers/2.png',
  },
  {
    name: 'Ray Cai',
    info: 'Research Director @ArkStream Capital',
    img: '/speakers/3.png',
  },
  {
    name: 'Maria',
    info: '中国区负责人 @Avail',
    img: '/speakers/4.png',
  },
  {
    name: 'Freya',
    info: 'VP @ZJUBCA',
    img: '/speakers/5.png',
  },
  {
    name: 'Leo Lara',
    info: 'Team Lead @Ethereum Foundation & Initiator @EthPadThai',
    img: '/speakers/6.png',
  },
  {
    name: 'Howe',
    info: 'Core Member @CUITBCA',
    img: '/speakers/7.png',
  },
  {
    name: '陈昕燃',
    info: 'Head of engineering @Reddio',
    img: '/speakers/9.png',
  },
  {
    name: 'Jern Kun',
    info: 'Initiator @EthPadThai, Research Engineer @PSE',
    img: '/speakers/10.png',
  },
  {
    name: 'Barry',
    info: 'SZUBA President & WeBank Blockchain PM @SZUBA & WeBank',
    img: '/speakers/12.png',
  },
  {
    name: 'Luka Li',
    info: 'Developer Relation Engineer @Morph',
    img: '/speakers/13.png',
  },
  {
    name: 'Kevin',
    info: 'Co-Founder @Artela',
    img: '/speakers/14.png',
  },
  {
    name: 'Tiny Xiong',
    info: 'Founder @登链社区',
    img: '/speakers/15.png',
  },
  {
    name: '超自然',
    info: '深大链协副会长 @深圳大学区块链学会',
    img: '/speakers/16.png',
  },
  {
    name: 'Beavn',
    info: 'Founder @江苏大学区块链学会',
    img: '/speakers/17.png',
  },
  {
    name: '航标',
    info: 'DevRel @Gear Foundation',
    img: '/speakers/18.png',
  },
  {
    name: 'Martin Tang',
    info: 'Co-Founder @Privasea.ai',
    img: '/speakers/19.png',
  },
  {
    name: 'Jiawei',
    info: 'Principal @IOSG Ventures',
    img: '/speakers/20.png',
  },
  {
    name: 'Peng Sun',
    info: 'Content Lead @BeWater',
    img: '/speakers/21.png',
  },
  {
    name: 'Steven',
    info: 'Contributor @Puffer Finance',
    img: '/speakers/22.png',
  },
  {
    name: 'ViVi',
    info: 'Co-Founder ZKP Labs',
    img: '/speakers/23.png',
  },
  {
    name: 'Mary Tran',
    info: 'CEO @Orochi Network',
    img: '/speakers/24.png',
  },
  {
    name: 'Tyrone',
    info: 'Dev Relation @Bifrost',
    img: '/speakers/25.png',
  },
  {
    name: 'Stanley He',
    info: 'Co-Founder @Luban Protocol',
    img: '/speakers/26.png',
  },
  {
    name: 'Jason Cui',
    info: 'Core Contributor @Panta Rhei',
    img: '/speakers/27.png',
  },
  {
    name: 'Zoe Li',
    info: 'Institution Lead @Mantle',
    img: '/speakers/28.png',
  },
  {
    name: 'Helena',
    info: 'Co-Founder @Verisense Network',
    img: '/speakers/29.png',
  },
  {
    name: 'Harold',
    info: 'System engineer @House Researcher',
    img: '/speakers/30.png',
  },
  {
    name: 'Franci Deng',
    info: 'Initiator @Panta Rhei',
    img: '/speakers/31.png',
  },
  {
    name: 'Anthurine',
    info: 'Funding Partner @EthStorage',
    img: '/agenda/10.jpeg',
  },
  {
    name: 'Marcus',
    info: 'APAC Contributor @Scroll',
    img: '/speakers/33.png',
  },
  {
    name: 'Chenxuan',
    info: 'Ph.D Student @Fudan University',
    img: '/agenda/46.jpeg',
  },
  {
    name: 'Jet Long',
    info: 'DevRel @Mina Foundation',
    img: '/speakers/35.png',
  },
  {
    name: 'David Park',
    info: 'Head of APAC @Mina Foundation',
    img: '/speakers/36.png',
  },
  {
    name: 'Robert Kodra',
    info: 'Developer Advocate @Starknet',
    img: Speaker22,
  },
  {
    name: 'Frank',
    info: 'DevRel @Chainlink',
    img: Speaker23,
  },
 
  {
    name: 'Tianyu',
    info: 'Research Engineer @Nethermind',
    img: Speaker101,
  },
  {
    name: 'Sungmo park',
    info: 'APAC Lead @Monad',
    img: Speaker103,
  },
  {
    name: 'Yoyo',
    info: 'Co-initiator @ETHPanda',
    img: '/speakers/37.png',
  },
  
  {
    name: 'More are coming ...',
    info: '',
    img: Speaker32,
  },
]

export default function Speakers() {
  return (
    <div className="speakers" id="speakers">
      <h3>Guest</h3>
      <p>Intended guest, all names arranged in no particular order</p>
      <div className="speakers-content">
        {data.map((i, k) => 
          <div key={k}>
            <div className="speakers-img">
              <img src={i.img} alt="" />
            </div>
            <div className="speakers-text">
              <h6>{i.name}</h6>
              <p>{i.info}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}