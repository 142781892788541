
import { useState } from 'react'

export default function Agenda() {
  const [currentDate, setCurrentDate] = useState(16)
  return (
    <div className="agenda-container" id="agenda">
      <div className="agenda">
        <div className="agenda-head">
          <button className={currentDate === 16 && 'active'} onClick={() => setCurrentDate(16)}><span>DAY0-</span>August 16</button>
          <button className={currentDate === 17 && 'active'} onClick={() => setCurrentDate(17)}><span>DAY1-</span>August 17</button>
          <button className={currentDate === 18 && 'active'} onClick={() => setCurrentDate(18)}><span>DAY2-</span>August 18</button>
        </div>
        <div className="agenda-schedule">
          {/* 16 */}
          {currentDate === 16 && (
            <ul>
              <li className="bg-yellow">
                <div className="agenda-content-first">
                  <p>14:00-15:00</p>
                  <p>Hackers 签到</p>
                </div>
              </li>
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>15:00-16:00 <span className="blue">Workshop</span></p>
                  <p className="fw-800">Build a full-stack dapp on Morph in 60minutes</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/1.png'} alt="" />
                    <div>
                      <h6>Luka</h6>
                      <p>DevRel</p>
                      <p>@Morph</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>16:00-17:00 <span className="blue">Workshop</span></p>
                  <p className="fw-800">Build zkApps with O1js on Mina</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/40.png'} alt="" />
                    <div>
                      <h6>Daniel</h6>
                      <p>DevRel</p>
                      <p>@Mina Foundation</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>17:00-18:00 <span className="blue">Workshop</span></p>
                  <p className="fw-800">使用 Sails 框架在 Vara 上开发 dApp</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/speakers/18.png'} alt="" />
                    <div>
                      <h6>Hangbiao</h6>
                      <p>DevRel</p>
                      <p>@Gear Foundation</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>18:00-19:00 <span className="blue">Workshop</span></p>
                  <p className="fw-800">通过Chainlink将智能合约扩展为混合智能合约</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/speakers/8.png'} alt="" />
                    <div>
                      <h6>Frank</h6>
                      <p>DevRel</p>
                      <p>@Chainlink</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-green">
                <div className="agenda-content-first">
                  <p>19:00-21:00</p>
                  <p>Ice-Breaker & Hackers' Party</p>
                </div>
              </li>
            </ul>
          )}
          {/* 17 */}
          {currentDate === 17 && (
            <ul>
              <li className="bg-yellow" style={{ height: '157px' }}>
                <div className="agenda-content-first">
                  <p>09:00-09:10</p>
                  <p>Opening Ceremony</p>
                </div>
                <div className="agenda-content-last agenda-content-last-yellow">
                  <div>
                    <img src={'/agenda/3.png'} alt="" />
                    <div>
                      <h6>Leo Lara</h6>
                      <p>Team Lead @Ethereum Foundation & Initiator @EthPadThai</p>
                      {/* <p>@EthpadThai</p> */}
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/47.jpeg'} alt="" />
                    <div>
                      <h6>Kery</h6>
                      {/* <p>Sr. SA</p> */}
                      <p>@知乎科技博主</p>
                    </div>
                  </div>
                </div>
              </li>
              
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>09:10-9:30 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">Data Availability Sampling from FRI</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/5.png'} alt="" />
                    <div>
                      <h6>Tian yu</h6>
                      <p>Research engineer</p>
                      <p>@Nethermind</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>09:30-09:50 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">Modern ZKP compilers</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/3.png'} alt="" />
                    <div>
                      <h6>Leo Lara</h6>
                      <p>Team Lead @Ethereum Foundation & Initiator @EthPadThai</p>
                      {/* <p>@EthpadThai</p> */}
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>09:50-10:10 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">Enhancing Ethereum Consensus Layer: Addressing Security Threats and Prevention Measures</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/46.jpeg'} alt="" />
                    <div>
                      <h6>Chenxuan</h6>
                      <p>Ph.D.</p>
                      <p>@FuDan University</p>
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>10:10-10:30 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">Standardization and popularization of Blockchain</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/7.png'} alt="" />
                    <div>
                      <h6>Victor Zhou</h6>
                      <p>EIP Editor, CEO of Namefi.io</p>
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-green">
                <div className="agenda-content-first">
                  <p>10:30-10:50</p>
                  <p>Rest & Communication</p>
                </div>
              </li>

              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>10:50-11:10 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">Provable Data in Web3 Economy</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/speakers/24.png'} alt="" />
                    <div>
                      <h6>Mary Tran</h6>
                      <p>CEO</p>
                      <p>@Orochi Network</p>
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-white"  style={{ height: '299px' }}>
                <div className="agenda-content-first">
                  <p>11:10-12:00 <span className="panel">Panel</span></p>
                  <p className="fw-800">Parallel EVM</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/26.png'} alt="" />
                    <div>
                      <h6>Sun peng </h6>
                      <p>Content Leader</p>
                      <p>@BeWater</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/41.png'} alt="" />
                    <div>
                      <h6>Sungmo park </h6>
                      <p>APAC Lead</p>
                      <p>@Monad</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/48.jpeg'} alt="" />
                    <div>
                      <h6>CP </h6>
                      <p>CTO</p>
                      <p>@Artela</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/8.png'} alt="" />
                    <div>
                      <h6>Chenxiran </h6>
                      <p>Head of Engineering</p>
                      <p>@Reddio</p>
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-green">
                <div className="agenda-content-first">
                  <p>12:00-14:00</p>
                  <p>Lunch break</p>
                </div>
              </li>

              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>14:00-14:20 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">Scaling Ethereum: A Deep Dive into Starknet</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/12.png'} alt="" />
                    <div>
                      <h6>Robert Kodra</h6>
                      <p>Developer Advocate</p>
                      <p>@Starknet Foundation</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>14:20-14:40 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">How the first ever Decentralized Sequencer network works</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/1.png'} alt="" />
                    <div>
                      <h6>Luka</h6>
                      <p>DevRel Engineer </p>
                      <p>@Morph</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white">
                <div className="agenda-content-first">
                  <p>14:40-15:00 <span className="yellow">Keynote</span></p>
                  <p className="fw-800">Emerging Large-Scale ZK Applications: The Synergy of Mina with Aligned Layer and Zeko</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/38.png'} alt="" />
                    <div>
                      <h6>David Park</h6>
                      <p>Head of APAC</p>
                      <p>@Mina Foundation</p>
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-white"  style={{ height: '373px' }}>
                <div className="agenda-content-first">
                  <p>15:00-15:40 <span className="panel">Panel</span></p>
                  <p className="fw-800">Modular Panel</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/42.png'} alt="" /> 
                    {/* todo */}
                    <div>
                      <h6>NFTDUDU </h6>
                      <p>China Lead</p>
                      <p>@RugRadio</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/8.png'} alt="" />
                    <div>
                      <h6>Chenxiran </h6>
                      <p>Head of Engineering</p>
                      <p>@Reddio</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/9.png'} alt="" />
                    <div>
                      <h6>Maria </h6>
                      <p>Head of China</p>
                      <p>@Avail</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/10.jpeg'} alt="" />
                    <div>
                      <h6>Anthurine </h6>
                      <p>Funding Partner</p>
                      <p>@EthStorage</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/11.png'} alt="" />
                    <div>
                      <h6>Marcus </h6>
                      <p>APAC Contributor</p>
                      <p>@Scroll</p>
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-green">
                <div className="agenda-content-first">
                  <p>15:40–16:00</p>
                  <p>三点几了，饮茶先啦。</p>
                </div>
              </li>

              <li className="bg-white"  style={{ height: '370px' }}>
                <div className="agenda-content-first">
                  <p>16:00-16:40 <span className="panel">Panel</span></p>
                  <p className="fw-800">Explore Restaking: Past and Future</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/16.png'} alt="" />
                    <div>
                      <h6>Jiawei </h6>
                      <p>Principal</p>
                      <p>@IOSG</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/17.png'} alt="" />
                    <div>
                      <h6>Steven </h6>
                      <p>Contributor</p>
                      <p>@Puffer Finance</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/18.png'} alt="" />
                    <div>
                      <h6>Tyrone </h6>
                      <p>Dev Relation</p>
                      <p>@Bifrost</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/19.png'} alt="" />
                    <div>
                      <h6>Zoe Li </h6>
                      <p>Institution Lead</p>
                      <p>@Mantle</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/20.png'} alt="" />
                    <div>
                      <h6>Helena </h6>
                      <p>Co-founder</p>
                      <p>@Verisense Network</p>
                    </div>
                  </div>
                </div>
              </li>

              <li className="bg-white"  style={{ height: '441px' }}>
                <div className="agenda-content-first">
                  <p>16:40-17:30 <span className="panel">Panel</span></p>
                  <p className="fw-800">FHE Panel</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/26.png'} alt="" />
                    <div>
                      <h6>Sun peng </h6>
                      <p>Content Leader</p>
                      <p>@BeWater</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/21.png'} alt="" />
                    <div>
                      <h6>Ray Cai </h6>
                      <p>Research Director</p>
                      <p>@ArkStream Capital</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/22.png'} alt="" />
                    <div>
                      <h6>Fubiao Xia </h6>
                      <p>Co-Founder</p>
                      <p>@PADO</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/23.png'} alt="" />
                    <div>
                      <h6>Martin Tang </h6>
                      <p>Co-Founder</p>
                      <p>@Privasea</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/24.png'} alt="" />
                    <div>
                      <h6>Jern Kunpittaya </h6>
                      <p>Researcher Engineer</p>
                      <p>@PSE</p>
                    </div>
                  </div>
                 
                  <div>
                    <img src={'/agenda/20.png'} alt="" />
                    <div>
                      <h6>Helena </h6>
                      <p>Co-Founder</p>
                      <p>@Verisense Network</p>
                    </div>
                  </div>
                  
                </div>
              </li>
              <li className="bg-yellow">
                <div className="agenda-content-first">
                  <p>18:00-22:00</p>
                  <p>After-Party</p>
                </div>
              </li>
            </ul>
          )}
          {/* 18 */}
          {currentDate === 18 && (
            <ul>
              <li className="bg-white"  style={{ height: '349px' }}>
                <div className="agenda-content-first">
                  <p>09:00-09:45 <span className="panel">Panel</span></p>
                  <p className="fw-800">Road to Decentralized ZKRollups</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/27.png'} alt="" />
                    <div>
                      <h6>Jason Cui </h6>
                      <p>Core Contributor</p>
                      <p>@Panta Rhei</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/28.png'} alt="" />
                    <div>
                      <h6>Stanley He </h6>
                      <p>Co-Founder</p>
                      <p>@Luban Protocol</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/29.png'} alt="" />
                    <div>
                      <h6>Harold </h6>
                      <p>system engineer</p>
                      <p>@House Researcher</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/30.png'} alt="" />
                    <div>
                      <h6>Franci Deng <span className="host">Host</span></h6>
                      <p>Initiator</p>
                      <p>@Panta Rhei</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/8.png'} alt="" />
                    <div>
                      <h6>Chenxiran </h6>
                      <p>Head of Engineering</p>
                      <p>@Reddio</p>
                    </div>
                  </div>
                  
                </div>
              </li>
              <li className="bg-white"  style={{ height: '299px' }}>
                <div className="agenda-content-first">
                  <p>09:45-10:30 <span className="panel">Panel</span></p>
                  <p className="fw-800">高校链协 Panel</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/31.png'} alt="" />
                    <div>
                      <h6>Barry</h6>
                      <p>SZUBA President</p>
                      <p>@深大链协</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/32.png'} alt="" />
                    <div>
                      <h6>Freya </h6>
                      <p>VP</p>
                      <p>@浙大链协 ZJUBCA</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/33.png'} alt="" />
                    <div>
                      <h6>Howe </h6>
                      <p>President</p>
                      <p>@成信大链协</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/34.png'} alt="" />
                    <div>
                      <h6>Beavn </h6>
                      <p>Founder</p>
                      <p>@江苏大学链协</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white"  style={{ height: '349px' }}>
                <div className="agenda-content-first">
                  <p>10:30-11:15 <span className="panel">Panel</span></p>
                  <p className="fw-800">东南亚社区共建</p>
                </div>
                <div className="agenda-content-last">
                  <div>
                    <img src={'/agenda/44.png'} alt="" />
                    <div>
                      <h6>Gian Alarcon </h6>
                      <p>Director</p>
                      <p>@Quantum3 Labs</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/24.png'} alt="" />
                    <div>
                      <h6>Jern Kunpittaya</h6>
                      <p>Initiator</p>
                      <p>@EthPadThai</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/43.jpg'} alt="" />
                    <div>
                      <h6>John Yu</h6>
                      <p>Partner</p>
                      <p>@登链社区</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/agenda/45.jpeg'} alt="" />
                    <div>
                      <h6>Qiuqiu</h6>
                      <p>Co-Founder</p>
                      <p>@OpenBuild</p>
                    </div>
                  </div>
                  <div>
                    <img src={'/speakers/37.png'} alt="" />
                    <div>
                      <h6>Yoyo</h6>
                      <p>Co-Founder</p>
                      <p>@ETHPanda</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}