
export default function About() {
  return (
    <div className="about" id="about">
      <p>August 16-18, Shenzhen</p>
      {/* <h2>About Road to DEVCON @ HongKong</h2> */}
      {/* <div>
        <p className="clip">Warm up for the upcoming DEVCON 7 in Southeast Asia and guide more APAC developers to participate in DEVCON <span>🚀</span></p>
      </div> */}
      <p>Join us in shaping the future of the Ethereum landscape. Let's innovate, collaborate, and build together.</p>
      <div>
        <p className="about-text">Join us for two days of immersive engagement in the Ethereum ecosystem right in the heart of Shenzhen. The Shenzhen Ethereum Developer Conference is a premier event <strong>designed to connect, inspire, and educate developers from the Guangdong-Hong Kong-Macao Greater Bay Area and beyond.</strong></p>
        <p className="about-text">With the increasing influx of developers relocating to Shenzhen, and benefit from Hong Kong's leading blockchain policies, this event serves as a golden opportunity to stay ahead in the fast-evolving blockchain space.<strong>We aim to foster a dynamic community that actively participates in the expansion and innovation in the Ethereum ecosystem.</strong></p>
      </div>
      <div>
        <a href="https://t.me/OpenBuildxyz/4976" className="telegram">
          Telegram group
          <span>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.5691 4.23677L17.6645 17.9352C17.4453 18.902 16.8738 19.1426 16.0617 18.6872L11.6359 15.4258L9.50039 17.4797C9.26406 17.7161 9.06641 17.9137 8.61094 17.9137L8.92891 13.4063L17.1316 5.99419C17.4883 5.67622 17.0543 5.50005 16.5773 5.81802L6.43672 12.2032L2.07109 10.8368C1.12148 10.5403 1.1043 9.88716 2.26875 9.43169L19.3445 2.85318C20.1352 2.55669 20.827 3.02935 20.5691 4.23677Z" fill="black"/>
            </svg>
          </span>
        </a>
        <a href="https://openbuild.xyz/learn/challenges/2040759955" target="_blank" rel="noreferrer"  className="register">
          Register
          <span>
            <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_120_14680)">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8075 5.76256C13.9063 5.76256 12.2538 1.49999 12.1825 1.31249C12.1027 1.09753 11.9409 0.922989 11.7326 0.82695C11.5244 0.730912 11.2866 0.720994 11.0712 0.79979C10.8549 0.881934 10.68 1.04672 10.5846 1.25755C10.4893 1.46839 10.4813 1.70829 10.5625 1.92497C11.157 3.39134 12.0768 4.7036 13.2525 5.76256H0.858765C0.646307 5.78809 0.450607 5.89056 0.308609 6.05064C0.166611 6.21073 0.0881958 6.4171 0.0881958 6.63109C0.0881958 6.84507 0.166611 7.05175 0.308609 7.21184C0.450607 7.37192 0.646307 7.47439 0.858765 7.49992H13.2525C12.0768 8.55888 11.157 9.87114 10.5625 11.3375C10.5223 11.4442 10.5035 11.5577 10.5072 11.6717C10.5109 11.7856 10.5369 11.8977 10.584 12.0016C10.631 12.1055 10.698 12.1992 10.7812 12.2771C10.8644 12.3551 10.9621 12.4161 11.0688 12.4563C11.1755 12.4965 11.289 12.5152 11.403 12.5115C11.5169 12.5078 11.6291 12.4816 11.733 12.4346C11.8369 12.3876 11.9305 12.3207 12.0084 12.2375C12.0864 12.1543 12.1473 12.0567 12.1875 11.95C12.2037 11.9 13.885 7.49992 17.8125 7.49992C18.0249 7.47379 18.2204 7.3708 18.3619 7.21031C18.5034 7.04982 18.5812 6.84294 18.5806 6.62895C18.58 6.41496 18.501 6.20849 18.3585 6.04881C18.2161 5.88914 18.0201 5.78747 17.8075 5.76256Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_120_14680">
                <rect width="18.6712" height="11.7625" fill="white" transform="translate(0 0.75)"/>
              </clipPath>
              </defs>
            </svg>
          </span>
        </a>
      </div>
    </div>
  )
}